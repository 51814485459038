<template>
  <div class="block">
    <!-- 消息通知 -->
    <div class="Notification" v-loading="loading">
      <div class="content">
        <div class="contentds">
          <!-- 智能问答 -->
          <div class="assembly">
            <div class="tops" v-if="
              this.InviteAnswers.length!=0
            ">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">用户回答邀请</span>
            </div>
            <el-timeline>
              <el-timeline-item
                class="tttop"
                v-for="item in InviteAnswers"
                :key="item.id"
                color="#0084FF"
              >
                <!-- 邀请 -->
                <div class="ZNinvitation">
                  <!-- 邀请头部 -->
                  <div>
                    <!-- 邀请人 -->
                    <span class="name">{{ item.name }}</span>
                    <span>邀请你回答问题</span>
                    <span>，标题为：{{ item.questionTitle }}</span>
                    <span
                      @click="answer(item.wdquestionid,item.userid,item.questionTitle,item.id)"
                      class="blue"
                    >是否去回答？</span>
                  </div>
                  <!-- //邀请回答的问题 -->
                  <div>
                    <span class="just">{{ item.creatime }}</span>
                    <span @click="deletes(item.id)" class="just">删除</span>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
            <div class="tops" v-if="this.InviteAnswersTeam.length!=0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">团队回答邀请</span>
            </div>
            <el-timeline>
              <el-timeline-item
                class="tttop"
                v-for="item in InviteAnswersTeam"
                :key="item.id"
                color="#0084FF"
              >
                <!--团队 邀请 回答-->
                <div class="ZNinvitation">
                  <!-- 邀请头部 -->
                  <div>
                    <!-- 邀请人 -->
                    <span class="name">{{ item.name }}</span>
                    <span>邀请你参与</span>
                    <span class="name" style="margin-left: 10px;">
                      {{
                      item.teamName
                      }}
                    </span>团队回答，
                    <span>问题标题为：{{ item.questionTitle }}</span>
                    <span
                      @click="
                  Teamanswer(
                    item.wdquestionid,
                    item.userid,
                    item.wdteamid,
                    item.questionTitle
                  )
                "
                      class="blue"
                    >是否参加团队回答？</span>
                  </div>
                  <!-- //邀请回答的问题 -->
                  <div>
                    <!-- 时间 -->
                    <span class="just">{{ item.creatime }}</span>
                    <span class="just" @click="deletes(item.id)">删除</span>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
            <!--    课题二   邀请   -->
            <!-- <div class="tops" v-if="
              this.Project.length!=0
            ">课题二邀请</div>-->
            <!-- <div class="ZNinvitation" v-for="item in Project" :key="item.id"> -->
            <!-- 邀请头部 -->
            <!-- <div class="tttop"> -->
            <!-- 邀请人 -->
            <!-- <span class="name">{{ item.name }}</span>
                <span>邀请你参与</span>
                <span class="name" style="margin-left: 10px;">
                  {{
                  item.teamName
                  }}
                </span>
            <span>的支持内容编辑</span>-->
            <!-- 时间 -->
            <!-- <span class="just">{{ item.creatime }}</span>
                <span @click="Teamanswer()" class="blue">点击去查看</span>
                <span @click="deletes(item.id)" class="cha"></span>
              </div>
            </div>-->
            <div class="tops" v-if="this.applyTeam == undefined || this.applyTeam.length != 0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">收到的申请入团</span>
            </div>
            <!-- 通知 -->
            <!-- 是否同意申请人加入该团队 完成-->
            <el-timeline>
              <el-timeline-item
                class="tttop"
                v-for="item in applyTeam"
                :key="item.id"
                color="#0084FF"
              >
                <div class="ZNnotice">
                  <!-- 头部 -->
                  <div>
                    <span class="name">{{ item.name }}</span>
                    <span class="td">申请加入你的</span>
                    <span class="name">{{ item.teamName }}</span>
                    <span>团队，</span>
                    <span
                      @click="
                  agree(
                    item.Wdnewsid,
                    item.id,
                    item.wdteamid,
                    item.user,
                    item.teamName
                  )
                "
                      class="blue"
                    >你同意吗？</span>
                  </div>
                  <div>
                    <span class="just">{{ item.creatime }}</span>
                    <span class="just" @click="deletes(item.id)">删除</span>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
            <div
              class="tops"
              v-if="
              this.ConsentApplication == undefined ||
                this.ConsentApplication.length != 0
            "
            >
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">入团申请被同意/拒绝</span>
            </div>
            <el-timeline>
              <el-timeline-item
                class="tttop"
                v-for="item in ConsentApplication"
                :key="item.id"
                color="#0084FF"
              >
                <!-- 同意/拒绝你加入团队的申请  完成-->
                <div class="ZNnotice">
                  <div>
                    <!-- 团长 -->
                    <span class="name">{{ item.name }}</span>
                    <span class="td">
                      <span v-if="item.agree == 1">同意</span>
                      <span v-else-if="item.agree == 2">拒绝</span>
                      <span v-else-if="item.agree == 3">退出</span>
                      了你
                    </span>
                    <!-- 团队 -->
                    <span
                      class="name"
                      v-if="item.teamName == undefined || item.teamName.length >= 50"
                    >
                      <span v-show="item.agree != 3">加入</span>
                      {{ item.teamName.substring(0, 50) + "..." }}
                    </span>
                    <span
                      class="name"
                      v-else-if="
                  item.teamName == undefined || item.teamName.length < 50
                "
                    >
                      <span v-show="item.agree != 3">加入</span>
                      {{ item.teamName }}
                    </span>
                    <span v-show="item.agree == 3">团队</span>
                    <span v-show="item.agree != 3">团队的申请</span>
                  </div>
                  <div>
                    <span class="just">{{ item.creatime }}</span>
                    <span @click="deletes(item.id)" class="just">删除</span>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
            <div
              class="tops"
              v-if="
              this.AgreeInviteAnswers == undefined ||
                this.AgreeInviteAnswers.length != 0
            "
            >被同意/拒绝收到邀请回答</div>
            <!-- 同意/拒绝你邀请回答的申请  完成-->
            <div class="ZNnotice tttop" v-for="item in AgreeInviteAnswers" :key="item.id">
              <div>
                <!-- 团长 -->
                <span class="name">{{ item.name }}</span>
                <span class="td">
                  <span v-if="item.agree == 1">同意</span>
                  <span v-else-if="item.agree == 2">拒绝</span>
                  了你邀请他（她）回答
                </span>
                <!-- 团队 -->
                <span class="name">{{ item.teamName }}</span>
                <span>问题的申请</span>
                <span class="just">{{ item.creatime }}</span>
                <span @click="deletes(item.id)" class="cha"></span>
              </div>
            </div>

            <div
              class="tops"
              style="margin-top:10px"
              v-if="this.Charging == undefined || this.Charging.length != 0"
            >
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">问题被查看</span>
            </div>
            <el-timeline>
              <el-timeline-item
                class="tttop"
                v-for="item in Charging"
                :key="item.id"
                color="#0084FF"
              >
                <!-- 通知收费问题被查看的消息 -->
                <div class="ZNnotice">
                  <div>
                    <!-- 团长 -->
                    <span class="name">{{ item.name }}</span>
                    <span class="td">查看你的</span>
                    <!-- 问题标题 -->
                    <span
                      class="name"
                      v-if="item.message == undefined || item.message.length <= 100"
                    >{{ item.message.substring(0, 40) + "..." }}</span>
                    <span
                      class="name"
                      v-if="item.message == undefined || item.message.length > 100"
                    >{{ item.message.substring(0, 60) + "..." }}</span>
                    <span>问题的答案并向你支付了</span>
                    <span style="color:red">{{ item.bill }}</span>
                    <span style="color:red">知识币</span>
                  </div>
                  <div>
                    <span class="just">{{ item.creatime }}</span>
                    <span @click="deletes(item.id)" class="just">删除</span>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
            <div class="tops" v-if="this.BeAnswered == undefined || this.BeAnswered.length != 0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">问题被回答</span>
            </div>
            <!-- 通知问题发布者 --发布的问题被回答  完成-->
            <el-timeline>
              <el-timeline-item
                class="tttop"
                v-for="(item, index) in BeAnswered"
                :key="index"
                color="#0084FF"
              >
                <div class="ZNnotice">
                  <div>
                    <!-- 回答者 -->
                    <!-- 单人问答 -->
                    <span class="name" v-if="item.person == 0">{{ item.name }}</span>
                    <span class="name" v-if="item.person == 1">{{ item.Teamname }}团队</span>
                    <span class="td">回答了你的</span>
                    <!-- 团队 -->
                    <!-- 问题内容 -->
                    <span
                      class="name feed"
                      v-if="item.message == undefined || item.message.length < 30"
                    >{{ item.message }}</span>
                    <span
                      class="name feed"
                      v-if="item.message == undefined || item.message.length >= 30"
                    >{{ item.message.substring(0, 30) + "..." }}</span>
                    <span>问题，</span>
                    <!-- 回答内容(单人) -->
                    <span v-if="item.person == 0">
                      <span>回答的答案为：</span>

                      <span
                        class="feed"
                      >{{ item.answer.substring(0, item.answer.length*0.5) + "..." }}</span>

                      <!-- 判断问题是否是悬赏 -->
                      <span
                        class="blue"
                        v-if="item.charge == 0"
                        @click="seede(item.wdquestionid, item.id)"
                      >去查看</span>
                      <span
                        class="blue"
                        v-if="item.adopt == 1"
                        @click="seede(item.wdquestionid, item.id)"
                      >已采纳该回答，点击查看答案</span>
                      <!-- competePay 竞价-->
                      <span
                        style="margin-left:20px"
                        v-if="item.charge == 1 && item.competeMode == 2"
                      >
                        他(她)的竞价金额是：
                        <span style="color:red">{{ item.competePay }}知识币</span>
                      </span>
                      <span
                        class="blue"
                        v-if="item.charge == 1 && item.adopt != 2"
                        @click="
                  adopted(
                    item.wdanswerrefid,
                    item.wdquestionid,
                    item.competePriceSetting,
                    item.beuserid,
                    item.id,
                    item.competePay,
                    item.competeMode,
                    item.person
                  )
                "
                      >是否采纳该回答？</span>
                      <!-- <span class="blue" v-if="item.adopt == 1">已采纳</span> -->
                      <span class="blue" v-if="item.adopt == 2">已拒绝</span>
                      <div>
                        <span class="just">{{ item.creatime }}</span>
                        <span @click="deletes(item.id)" class="just">删除</span>
                      </div>
                    </span>
                    <!-- <span class="just">{{ item.creatime }}</span> -->
                    <span v-if="item.person == 1">
                      <!-- 判断问题是否是悬赏 -->
                      <span
                        class="blue"
                        v-if="item.charge == 0"
                        @click="seede(item.wdquestionid, item.id)"
                      >去查看</span>
                      <span
                        class="blue"
                        v-if="item.adopt == 1"
                        @click="seede(item.wdquestionid, item.id)"
                      >已采纳该回答，点击查看答案</span>
                      <!-- competePay 竞价-->
                      <span
                        style="margin-left:20px"
                        v-if="item.charge == 1 && item.competeMode == 2"
                      >
                        该团队的竞价金额是：
                        <span style="color:red">{{ item.competePay }}知识币</span>
                      </span>
                      <span
                        class="blue"
                        v-if="item.charge == 1 && item.adopt != 2"
                        @click="
                    adopted(
                      item.wdanswerrefid,
                      item.wdquestionid,
                      item.competePriceSetting,
                      item.beuserid,
                      item.id,
                      item.competePay,
                      item.competeMode,
                      item.person
                    )
                  "
                      >是否采纳该回答？</span>
                      <span class="blue" v-if="item.adopt == 2">已拒绝</span>
                    </span>
                    <!-- <span @click="deletes(item.id)" class="just">删除</span> -->
                  </div>
                  <!-- 回答内容(多人) -->
                  <div class="tttop" v-if="item.person == 1">
                    <!-- 多人问答的样式 -->
                    <div class="people">
                      <div v-for="onepcomment in BeAnswered[index].sons" :key="onepcomment.id">
                        <span class="name">{{ onepcomment.name }}</span>
                        <span>回答的答案为：</span>
                        <span class="feed">
                          {{
                          onepcomment.answer_content.substring(0, onepcomment.answer_content.length*0.5) + "..."
                          }}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span class="just">{{ item.creatime }}</span>
                      <span @click="deletes(item.id)" class="just">删除</span>
                    </div>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>

            <div
              class="tops"
              v-if="this.CreditBeAnswered == undefined || this.CreditBeAnswered.length != 0"
            >
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">（信用度）问题被回答</span>
            </div>
            <!-- 信用度 查看-->
            <el-timeline>
              <el-timeline-item v-for="item in CreditBeAnswered" :key="item.id" color="#0084FF">
                <div class="ZNnotice tttop">
                  <div>
                    <span>
                      <!-- 团长 -->
                      <span class="name">
                        {{ item.name }}
                        <span style="color: #9e9e9e;font-weight:none">
                          (信用度为{{item.creditRating}})
                          <!-- 信用度为{{ item.wdAcceptNum }} -->
                        </span>
                      </span>
                      <span class="td">回答了你的</span>
                      <!-- 团队 -->
                      <span
                        class="name feed"
                        v-if="item.message == undefined || item.message.length < 30"
                      >{{ item.message }}</span>
                      <span
                        class="name feed"
                        v-if="item.message == undefined || item.message.length >= 30"
                      >{{ item.message.substring(0, 30) + "..." }}</span>
                      <span>问题</span>
                    </span>
                    <!-- 回答内容 -->
                    <span>
                      <span>回答的答案为：</span>
                      <span
                        class="feed"
                      >{{ item.answer.substring(0, item.answer.length*0.5) + "..." }}</span>

                      <!-- 判断问题是否是悬赏 -->
                      <span
                        class="blue"
                        v-if="item.charge == 0"
                        @click="seede(item.wdquestionid, item.id)"
                      >去查看</span>
                      <span
                        class="blue"
                        v-if="item.adopt == 1"
                        @click="seede(item.wdquestionid, item.id)"
                      >已采纳该回答，点击查看答案</span>
                      <!-- competePay 竞价-->
                      <span
                        style="margin-left:20px"
                        v-if="item.charge == 1 && item.competeMode == 2"
                      >
                        他(她)的竞价金额是：
                        <span style="color:red">{{ item.competePay }}知识币</span>
                      </span>
                      <span
                        class="blue"
                        v-if="item.charge == 1 && item.adopt != 2"
                        @click="
                  adopted(
                    item.wdanswerrefid,
                    item.wdquestionid,
                    item.competePriceSetting,
                    item.beuserid,
                    item.id,
                    item.competePay,
                    item.competeMode,
                    item.person
                  )
                "
                      >是否采纳该回答？</span>
                      <!-- <span class="blue" v-if="item.adopt == 1">已采纳</span> -->
                      <span class="blue" v-if="item.adopt == 2">已拒绝</span>
                    </span>
                  </div>
                  <div>
                    <span class="just">{{ item.creatime }}</span>
                    <span @click="deletes(item.id)" class="just">删除</span>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
            <div
              class="tops"
              v-if="
              this.ShowAdoption == undefined || this.ShowAdoption.length != 0
            "
            >
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">回答的问题是否被采纳</span>
            </div>
            <!-- 回答的问题是否被采纳 -->
            <!-- 竞价问题是否被采纳  完成-->
            <el-timeline>
              <el-timeline-item
                class="tttop"
                v-for="item in ShowAdoption"
                :key="item.id"
                color="#0084FF"
              >
                <div class="ZNnotice">
                  <div>
                    <!-- 团长 -->
                    <span class="name">{{ item.name }}</span>
                    <span v-if="item.agree == 1">采纳</span>
                    <span v-else-if="item.agree == 2">拒绝</span>
                    <span class="td">了你的</span>
                    <!-- 答案 -->
                    <span>{{ item.answer.substring(0, item.answer.length*0.5) + "..." }}</span>
                    回答
                    <!-- 回答内容 -->
                    <span v-if="item.agree == 1">
                      <span>并获得了</span>
                      <span style="color:red">{{ item.bill }}</span>
                      <span style="color:red">知识币</span>
                    </span>
                  </div>
                  <div>
                    <span class="just">{{ item.creatime }}</span>
                    <span @click="deletes(item.id)" class="just">删除</span>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>

            <div class="tops" v-if="thirtyEight.length>0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">入团邀请</span>
            </div>
            <el-timeline>
              <template v-for="item in thirtyEight">
                <el-timeline-item
                  class="tttop"
                  v-if="item.inviterName"
                  :key="item.id"
                  color="#0084FF"
                >
                  <div class="ZNinvitation">
                    <div>
                      <span class="name">{{ item.inviterName||'' }}</span>
                      <span>邀请你加入</span>
                      <span class="name" style="margin-left: 10px;">{{item.TeamName}}</span>团队回答，
                      <span>
                        问题标题为：
                        <span class="name">{{ item.issueTitle||'' }}</span>
                      </span>
                      <span class="blue" @click="tapParticipation(item)">是否参加团队回答？</span>
                    </div>
                    <div>
                      <span class="just">{{ showTimeDate(item.creatime) }}</span>
                      <span class="just" @click="deletes(item.id)">删除</span>
                    </div>
                  </div>
                </el-timeline-item>
                <el-timeline-item class="tttop" v-else :key="item.id" color="#0084FF">
                  <div class="ZNinvitation">
                    <div>
                      <span class="name">当前问题已被删除</span>
                    </div>
                    <div>
                      <span class="just">{{ showTimeDate(item.creatime) }}</span>
                      <span class="just" @click="deletes(item.id)">删除</span>
                    </div>
                  </div>
                </el-timeline-item>
              </template>
            </el-timeline>
          </div>
        </div>
      </div>
      <div v-if="loading">
        <V-Loading></V-Loading>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "@/views/wisdom-answer/index/team-response/Pop/Loading/index.vue";
import { DELETE, QUERYED, INSERT, UPDATE, QUERY } from "@/services/dao.js";
import {
  updateNews,
  formatDate,
  AcceptNum,
  AcceptNumTeam,
  showTimeFormat,
  integralAddFive,
  integralAddThree
} from "@/js/common/index.js";
import {
  formatZero,
  getTimeFormat,
  getTimeFormat_1
} from "@/js/common/Resoures";

export default {
  data() {
    return {
      son: [],
      loading: false, //是否显示正在加载中
      teamTypes: "",
      visable: false,
      classification: -1,
      applyTeam: [], //////团队申请
      InviteAnswers: [], //邀请回答
      InviteAnswersTeam: [], //团队邀请回答
      ConsentApplication: [], //被同意或者拒绝入团的申请
      AgreeInviteAnswers: [], ///是否被拒绝邀请回答问题的申请
      Charging: [], ///查看收费问题受到的通知
      BeAnswered: [], //显示被回答的消息
      ShowAdoption: [], //回答的问题是否被采纳
      CreditBeAnswered: [], //问题被查看（信用度排序）
      Project: [], //课题二邀请
      thirtyEight: [], //入团邀请
      silitn: [],
      billlist: [],
      billlista: [],
      userid: null, //用户id
      bill_subs_other: [], // 订阅他人
      bill_subsed_other: [] // 被他人订阅
    };
  },
  created() {
    //alert(this.$store.state.userinfo.id)
    this.userid = this.$store.state.userinfo.id;
    this.init();
    // this.timer();
  },
  components: {
    "V-Loading": Loading //正在加载中
  },
  methods: {
    //  // 这是一个定时器
    //   timer() {
    //     window.setInterval(() => {
    //      setTimeout(() => {
    //      ///调取接口获取数据
    //      this.timing();
    //     }, 0)
    //     }, 1000);///每一秒刷新一次
    //   },
    ///定时刷新是否有新消息
    // async timing(){
    //   //alert(1)
    //   ///如果等了了用户
    //   if (
    //   this.$store.state.userinfo.id != null ||
    //   this.$store.state.userinfo.id != ""
    // )
    // {
    //   //如果登陆了用户
    //   let info = await QUERYED(
    //     "POST",
    //     "",
    //     "  MEssageNotification_aggregate(where: {userid: {_eq: " +
    //       this.$store.state.userinfo.id +
    //       "}, identification: {_eq: 0}}) { aggregate {    count  }  }"
    //   );
    //   if (info.data.MEssageNotification_aggregate.aggregate.count > 0) {
    //     ///如果存在未读信息
    //     this.init();
    //   }
    // }
    // },
    ////清除定时器
    // destroyed(){
    //   clearInterval(this.timing);
    // },
    // 初次加载
    async init() {
      if (this.userid != undefined) {
        /////修改方式为已读
        let updateNews = await UPDATE(
          "post",
          "",
          "update_MessageNotification(where: {userid: {_eq: " +
            this.$store.state.userinfo.id +
            "}}, _set: {identification : 1}) { affected_rows  }  "
        );
        if (updateNews.data.update_MessageNotification.affected_rows > 0) {
          //console.log("修改为已读成功！");
        }
        this.TeamInviteAnswersed(); //团队邀请回答
        this.applyTeamS(); ////加载显示团队的入团申请
        this.InviteAnswersed(); ///加载邀请回答问题
        this.ConsentApplicationed(); //是否拒绝你的团队申请
        this.AgreeInviteAnswersed(); ///显示加载邀请人是否同意回答问题
        this.BeAnsweredwd(); ///显示谁回答我的问题的通知
        this.ShowAdoptionAdopt(); //显示是否显示拒绝或者采纳你的回答
        this.Charginged(); //谁查看了我的问题
        this.Projects(); //课题二邀请
        this.getThirtyEight();
      }
    },
    ///谁查看了我的问题回答
    async Charginged() {
      this.Charging = []; //清空
      let info = await QUERYED(
        "POST",
        "",
        "  WDMessageNotification(where: {state: {_eq: 2}, userid: {_eq: " +
          this.userid +
          "}}, distinct_on: messagenotificationid,order_by: {messagenotificationid: desc}) { messagenotificationid   name    message    creatime   bill }"
      );
      // if(info!=null)
      if (JSON.stringify(info.data.WDMessageNotification) != undefined) {
        this.Charging.splice(0, this.Charging.length);
        for (let i = 0; i < info.data.WDMessageNotification.length; i++) {
          this.Charging.push({
            id: info.data.WDMessageNotification[i].messagenotificationid, //消息通知ID
            creatime: showTimeFormat(
              info.data.WDMessageNotification[i].creatime
            ),
            name: info.data.WDMessageNotification[i].name,
            message: info.data.WDMessageNotification[i].message, ///问题标题
            bill: info.data.WDMessageNotification[i].bill //查看价格
          });
        }
      }
    },
    //显示  是否被采纳或者拒绝问题的回答
    async ShowAdoptionAdopt() {
      this.ShowAdoption = [];
      let info = await QUERYED(
        "POST",
        "",
        "  WdAnswerRefMessageNotification(where: {userid: {_eq: " +
          this.userid +
          "}}, distinct_on: id,order_by: {id: desc}) {  creatime  name bill   id   answer   agree  }"
      );
      if (
        JSON.stringify(info.data.WdAnswerRefMessageNotification) != undefined
      ) {
        this.ShowAdoption.splice(0, this.ShowAdoption.length);
        for (
          let i = 0;
          i < info.data.WdAnswerRefMessageNotification.length;
          i++
        ) {
          this.ShowAdoption.push({
            id: info.data.WdAnswerRefMessageNotification[i].id, //消息通知ID
            agree: info.data.WdAnswerRefMessageNotification[i].agree, ///采纳状态
            creatime: showTimeFormat(
              info.data.WdAnswerRefMessageNotification[i].creatime
            ),
            name: info.data.WdAnswerRefMessageNotification[i].name,
            answer: info.data.WdAnswerRefMessageNotification[i].answer, ///回答内容
            bill: info.data.WdAnswerRefMessageNotification[i].bill //采纳价格
          });
        }
      }
    },
    ///谁回答了我的问题的消息
    async BeAnsweredwd() {
      /////除信用度之外
      let a = null;
      let b = null;
      this.BeAnswered = [];
      this.CreditBeAnswered = [];
      let info = await QUERYED(
        "POST",
        "",
        "WdQuestionMessageNotification(where: {state: {_eq: 4},  userid: {_eq: " +
          this.userid +
          "},_not: {competeMode: {_eq: 4}}},order_by: {id: desc}, distinct_on: id) { id Teamname adopt person competePriceSetting charge answer wdanswerrefid competePay  name   message competeMode creatime wdquestionid  beuserid }"
      );
      // if(JSON.stringify(info.data.WdQuestionMessageNotification)!=undefined)
      {
        this.BeAnswered.splice(0, this.BeAnswered.length);
        for (
          let i = 0;
          i < info.data.WdQuestionMessageNotification.length;
          i++
        ) {
          if (info.data.WdQuestionMessageNotification[i].person == 0) {
            //单人问答
            this.BeAnswered.push({
              wdquestionid:
                info.data.WdQuestionMessageNotification[i].wdquestionid, //问题ID
              wdanswerrefid:
                info.data.WdQuestionMessageNotification[i].wdanswerrefid,
              beuserid: info.data.WdQuestionMessageNotification[i].beuserid, ///写回答者的ID
              id: info.data.WdQuestionMessageNotification[i].id, //消息通知ID
              creatime: showTimeFormat(
                info.data.WdQuestionMessageNotification[i].creatime
              ),
              name: info.data.WdQuestionMessageNotification[i].name,
              answer: info.data.WdQuestionMessageNotification[i].answer,
              message: info.data.WdQuestionMessageNotification[i].message, ///回答编号
              competeMode:
                info.data.WdQuestionMessageNotification[i].competeMode, ///悬赏方式
              competePriceSetting:
                info.data.WdQuestionMessageNotification[i].competePriceSetting, //悬赏的价格
              competePay: info.data.WdQuestionMessageNotification[i].competePay, //竞价金额
              charge: info.data.WdQuestionMessageNotification[i].charge, //判断收费方式
              adopt: info.data.WdQuestionMessageNotification[i].adopt, //判断是否被拒绝
              person: info.data.WdQuestionMessageNotification[i].person //（单人问答/多人问答）
            });
          } else if (info.data.WdQuestionMessageNotification[i].person == 1) {
            //alert(info.data.WdQuestionMessageNotification[i].wdanswerrefid)
            // 查找回答问题被采纳的回答者ID
            let AnswerRefID = await QUERYED(
              "POST",
              "",
              "WDUserQuestionAnswerRef(where:  {wdanswerref: {_eq: " +
                info.data.WdQuestionMessageNotification[i].wdanswerrefid +
                "}}) {   user external_teamid external_problemid }"
            );
            //console.log(
            //   "外部内部：",
            //   AnswerRefID.data.WDUserQuestionAnswerRef[0].external_teamid,
            //   AnswerRefID.data.WDUserQuestionAnswerRef[0].external_problemid
            // );
            ///通过外部团队ID和问题ID查找多人多人回答的内容
            let people_answer = await QUERYED(
              "POST",
              "",
              "WDpeople_answer(where: {team_id : {_eq: " +
                AnswerRefID.data.WDUserQuestionAnswerRef[0].external_teamid +
                "},ques_id  : {_eq: " +
                AnswerRefID.data.WDUserQuestionAnswerRef[0].external_problemid +
                "}}) {   id name user_id  answer_content}"
            );
            a = info.data.WdQuestionMessageNotification[i];
            a.creatime = showTimeFormat(
              info.data.WdQuestionMessageNotification[i].creatime
            );
            //alert(people_answer.data.WDpeople_answer.length)
            //     for(let a = 0;
            //   a < people_answer.data.WDpeople_answer.length;
            //  a++)
            //  {
            //    //console.log("我是团队回答答案",people_answer.data.WDpeople_answer[a])
            //    this.son.push(people_answer.data.WDpeople_answer[a])
            //  }
            a.sons = people_answer.data.WDpeople_answer;
            // (this.son = people_answer.data.WDpeople_answer); ///显示详情
            //alert(JSON.stringify(this.son))
            this.BeAnswered.push(a); ///赋值多人（团队）问答
          }

          ///通过编号查找该问题是否被采纳
          // let data = await QUERYED("post", "", "");
        }
      }
      ///////////信用度
      ///消息表
      let info1 = await QUERYED(
        "POST",
        "",
        "  WDCreditQuestionMessageNotification(where: {state: {_eq: 4},  userid: {_eq: " +
          this.userid +
          "}}, distinct_on: id,order_by: {id: desc}) {adopts person id beuserid Teamname wdAcceptNum competePriceSetting charge answer wdanswerrefid competePay   name    message     competeMode     creatime   wdquestionid  beuserid }"
      );
      if (
        JSON.stringify(info1.data.WDCreditQuestionMessageNotification.length) !=
        undefined
      ) {
        this.CreditBeAnswered.splice(0, this.CreditBeAnswered.length);
        for (
          let i = 0;
          i < info1.data.WDCreditQuestionMessageNotification.length;
          i++
        ) {
          let User = await QUERYED(
            "POST",
            "",
            "User(where:{id:{_eq:" +
              info1.data.WDCreditQuestionMessageNotification[i].beuserid +
              "}}) {creditRating}"
          );
          console.log(User, "信用等级");
          ///通过编号查找该问题是否被采纳
          // let data = await QUERYED("post", "", "");
          this.CreditBeAnswered.push({
            creditRating: User.data.User[0].creditRating,
            Team: info1.data.WDCreditQuestionMessageNotification[i].Teamname, //团队名称
            wdquestionid:
              info1.data.WDCreditQuestionMessageNotification[i].wdquestionid, //问题ID
            wdanswerrefid:
              info1.data.WDCreditQuestionMessageNotification[i].wdanswerrefid,
            beuserid:
              info1.data.WDCreditQuestionMessageNotification[i].beuserid, ///写回答者的ID
            id: info1.data.WDCreditQuestionMessageNotification[i].id, //消息通知ID
            creatime: showTimeFormat(
              info1.data.WDCreditQuestionMessageNotification[i].creatime
            ),
            name: info1.data.WDCreditQuestionMessageNotification[i].name,
            answer: info1.data.WDCreditQuestionMessageNotification[i].answer,
            message: info1.data.WDCreditQuestionMessageNotification[i].message, ///回答编号
            competeMode:
              info1.data.WDCreditQuestionMessageNotification[i].competeMode, ///悬赏方式
            competePriceSetting:
              info1.data.WDCreditQuestionMessageNotification[i]
                .competePriceSetting, //悬赏的价格
            competePay:
              info1.data.WDCreditQuestionMessageNotification[i].competePay, //竞价金额
            charge: info1.data.WDCreditQuestionMessageNotification[i].charge, //判断收费方式
            wdAcceptNum:
              info1.data.WDCreditQuestionMessageNotification[i].wdAcceptNum, //信用度
            adopt: info1.data.WDCreditQuestionMessageNotification[i].adopts, //判断是被拒绝
            person: info1.data.WDCreditQuestionMessageNotification[i].person //回答方式
          });
        }
      }
    },
    ////邀请回答问题
    // 团队邀请回答
    async TeamInviteAnswersed() {
      this.InviteAnswersTeam = []; //先清空
      let info = await QUERYED(
        "POST",
        "",
        "   WDTeamInvitationMessageNotification(where: {beuserid: {_eq: " +
          this.userid +
          "}}, distinct_on: id,order_by: {id: desc}) {userid  questionTitle wdteamid teamName  name  wdquestionid  id invitationid  creatime  }"
      );
      if (
        JSON.stringify(info.data.WDTeamInvitationMessageNotification) !=
        undefined
      ) {
        // this.InviteAnswersTeam.splice(0, this.InviteAnswersTeam.length);
        for (
          let i = 0;
          i < info.data.WDTeamInvitationMessageNotification.length;
          i++
        ) {
          this.InviteAnswersTeam.push({
            wdquestionid:
              info.data.WDTeamInvitationMessageNotification[i].wdquestionid, //问题ID
            userid: info.data.WDTeamInvitationMessageNotification[i].userid, //用户ID
            id: info.data.WDTeamInvitationMessageNotification[i].id,
            creatime: showTimeFormat(
              info.data.WDTeamInvitationMessageNotification[i].creatime
            ),
            name: info.data.WDTeamInvitationMessageNotification[i].name,
            questionTitle:
              info.data.WDTeamInvitationMessageNotification[i].questionTitle,
            teamName: info.data.WDTeamInvitationMessageNotification[i].teamName,
            wdteamid: info.data.WDTeamInvitationMessageNotification[i].wdteamid //团队ID
          });
        }
      }
    },
    ///课题二邀请
    async Projects() {
      this.Project = []; //先清空
      let info = await QUERYED(
        "POST",
        "",
        "WDMessageNotification(where: {userid: {_eq: " +
          this.userid +
          "},state: {_eq:5}}) {messagenotificationid type userid creatime work_id work_name expiration_time name }"
      );
      for (let i = 0; i < info.data.WDMessageNotification.length; i++) {
        this.Project.push({
          id: info.data.WDMessageNotification[i].messagenotificationid,
          creatime: showTimeFormat(info.data.WDMessageNotification[i].creatime),
          name: info.data.WDMessageNotification[i].name,
          teamName: info.data.WDMessageNotification[i].work_name
        });
      }
    },

    //入团邀请
    async getThirtyEight() {
      // thirtyEight
      let info = await QUERYED(
        "POST",
        "",
        "MessageNotification(where: {userid: {_eq: " +
          this.userid +
          "},state: {_eq:8}}) {id question creatime TeamName beuserid teamType teamVow }"
      );
      if (info.success) {
        let then = this;
        async function getUserAndTitlte(list, num) {
          if (num >= list.length) {
            return;
          }
          let user = await QUERYED(
            "POST",
            "",
            "User(where: {id: {_eq: " +
              list[num].beuserid +
              "}}) { loginName name }"
          );
          then.$set(
            list[num],
            "inviterName",
            user.data.User[0].name || user.data.User[0].loginName
          );
          console.log(user.data, "userid");
          let title = await QUERYED(
            "POST",
            "",
            "WdQuestion(where: {id: {_eq: " +
              list[num].question +
              "}}) { questionTitle }"
          );
          then.$set(
            list[num],
            "issueTitle",
            title.data.WdQuestion[0].questionTitle
          );
          then.$set(
            list[num],
            "issueTitle",
            title.data.WdQuestion[0].questionTitle
          );
          num++;
          getUserAndTitlte(list, num);
        }
        let list = info.data.MessageNotification;
        this.thirtyEight = list;
        getUserAndTitlte(this.thirtyEight, 0);
      }
      // this.
      // console.log(info,123);
      // for (let i = 0; i < info.data.WDMessageNotification.length; i++) {
      //   this.Project.push({
      //     id: info.data.WDMessageNotification[i].messagenotificationid,
      //     creatime: showTimeFormat(info.data.WDMessageNotification[i].creatime),
      //     name: info.data.WDMessageNotification[i].name,
      //     teamName: info.data.WDMessageNotification[i].work_name
      //   });
      // }
    },

    //显示时间
    showTimeDate(value) {
      let date = new Date(value || 0);
      let Y = date.getFullYear();
      let MM = date.getMonth() + 1;
      let D = date.getDate();
      let h = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      return `${Y}-${MM > 9 ? MM : "0" + MM}-${D > 9 ? D : "0" + D} ${h}:${
        m > 9 ? m : "0" + m
      }:${s > 9 ? s : "0" + s}`;
    },

    //点击参与
    async tapParticipation(item) {
      let issueId = item.question;
      let then = this;
      // 获取团队id
      let WdInvitation = await QUERYED(
        "POST",
        "",
        `WdInvitation(where:{questioner :{_eq:${issueId}}}){team}`
      );
      if (!WdInvitation.success) {
        then.$message({
          type: "warning",
          message: "获取数据失败，请重试"
        });
        return;
      }
      let teamId = "";
      for (let item of WdInvitation.data.WdInvitation) {
        if (item.team) {
          teamId = item.team;
          break;
        }
      }
      //查询是否加入团队
      let WdTeamMember = await QUERYED(
        "POST",
        "",
        `WdTeamMember(where:{team:{_eq:${teamId}},member:{_eq:${then.userid}}}){id}`
      );
      if (!WdTeamMember.success) {
        then.$message({
          type: "warning",
          message: "获取数据失败，请重试"
        });
        return;
      }
      if (WdTeamMember.data.WdTeamMember.length > 0) {
        then.$message({
          type: "warning",
          message: "您已加入该团队"
        });
        // this.$router.push({path:'/writeanswer',query:{id:issueId}})
      } else {
        //同意
        async function consent() {
          console.log("同意");
          let insertData = await INSERT(
            "post",
            "",
            `insert_WdTeamMember(objects: {team:${teamId},member:${
              then.userid
            },joinTime:"${new Date()}",Leader:""}) {affected_rows returning{id}}`
          );
          if (insertData.success) {
            // 跳转到问题详情
            then.$router.push({ path: "/writeanswer", query: { id: issueId } });
            //跳到多人问答
            // then.agreddTeamanswer(issueId, then.userid, teamId); //同意回答    （问题id，当前用户id，团队id）
          } else {
            then.$message({
              type: "warning",
              message: "获取数据失败，请重试"
            });
          }
        }

        //拒绝
        async function refuse() {
          console.log("拒绝");
          let WdTeamMember = await QUERYED(
            "POST",
            "",
            `WdQuestion(where:{id:{_eq:${teamId}}}){questionTitle}`
          );
          if (WdTeamMember.success) {
            //已拒绝某人的回答问题邀请
            then.JJquestion(
              issueId,
              then.userid,
              WdTeamMember.data.WdTeamMember[0].questionTitle
            ); //（问题id，当前用户id，问题title）
          } else {
            then.$message({
              type: "warning",
              message: "获取数据失败，请重试"
            });
          }
        }

        then
          .$confirm("是否同意加入该团并且去回答该团队问题？", "系统提示", {
            confirmButtonText: "同意",
            cancelButtonText: "拒绝",
            type: "warning",
            showClose: false,
            closeOnClickModal: false
          })
          .then(consent)
          .catch(refuse);
      }
    },

    ///单人邀请回答 收消息
    async InviteAnswersed() {
      this.InviteAnswers = [];
      let info = await QUERYED(
        "POST",
        "",
        "   WDInvitationMessageNotification(where: {beuserid: {_eq: " +
          this.userid +
          "}}, distinct_on: id,order_by: {id: desc}) {userid  questionTitle   name  wdquestionid  id invitationid  creatime  }"
      );
      if (
        JSON.stringify(info.data.WDInvitationMessageNotification) != undefined
      ) {
        this.InviteAnswers.splice(0, this.InviteAnswers.length);
        for (
          let i = 0;
          i < info.data.WDInvitationMessageNotification.length;
          i++
        ) {
          this.InviteAnswers.push({
            wdquestionid:
              info.data.WDInvitationMessageNotification[i].wdquestionid,
            userid: info.data.WDInvitationMessageNotification[i].userid,
            id: info.data.WDInvitationMessageNotification[i].id,
            creatime: showTimeFormat(
              info.data.WDInvitationMessageNotification[i].creatime
            ),
            name: info.data.WDInvitationMessageNotification[i].name,
            questionTitle:
              info.data.WDInvitationMessageNotification[i].questionTitle
          });
        }
      }
    },
    ///显示是否接受邀请回答的申请
    ///收消息
    async AgreeInviteAnswersed() {
      this.AgreeInviteAnswers = [];
      let info = await QUERYED(
        "POST",
        "",
        "     WDMessageNotification(where: {state: {_eq: 1}, userid: {_eq: " +
          this.userid +
          "}}, distinct_on: messagenotificationid,order_by: {messagenotificationid: desc}) {    agree    creatime   message   name    messagenotificationid  }"
      );
      if (JSON.stringify(info.data.WDMessageNotification) != undefined) {
        this.AgreeInviteAnswers.splice(0, this.AgreeInviteAnswers.length);
        for (let i = 0; i < info.data.WDMessageNotification.length; i++) {
          this.AgreeInviteAnswers.push({
            id: info.data.WDMessageNotification[i].messagenotificationid, //通知ID
            agree: info.data.WDMessageNotification[i].agree, //是否同意
            creatime: showTimeFormat(
              info.data.WDMessageNotification[i].creatime
            ), //时间
            name: info.data.WDMessageNotification[i].name, //邀请人姓名
            teamName: info.data.WDMessageNotification[i].message //问题内容
          });
        }
      }
    },
    // 是否同意某某加入我的某某团队
    ///发消息
    async applyTeamS() {
      this.applyTeam = [];
      let info = await QUERYED(
        "POST",
        "",
        "  WDnewsMessageNotification(where: {beuserid: {_eq: " +
          this.userid +
          "}}, distinct_on: messagenotificationid,order_by: {messagenotificationid: desc}) { id  wdteamid messagenotificationid  creatime  name user   teamName  }"
      );
      if (JSON.stringify(info.data.WDnewsMessageNotification) != undefined) {
        this.applyTeam.splice(0, this.applyTeam.length);
        for (let i = 0; i < info.data.WDnewsMessageNotification.length; i++) {
          this.applyTeam.push({
            Wdnewsid: info.data.WDnewsMessageNotification[i].id, //消息ID
            id: info.data.WDnewsMessageNotification[i].messagenotificationid, //通知ID
            wdteamid: info.data.WDnewsMessageNotification[i].wdteamid, //团队ID
            user: info.data.WDnewsMessageNotification[i].user, //申请人的ID
            creatime: showTimeFormat(
              info.data.WDnewsMessageNotification[i].creatime
            ),
            name: info.data.WDnewsMessageNotification[i].name,
            teamName: info.data.WDnewsMessageNotification[i].teamName
          });
        }
      }
    },
    //显示是否同意或者拒绝的团队申请
    ///收消息
    async ConsentApplicationed() {
      this.ConsentApplication = [];
      let info = await QUERYED(
        "POST",
        "",
        "  WDMessageNotification(where: {userid: {_eq: " +
          this.userid +
          "}, state: {_eq: 0}}, distinct_on: messagenotificationid,order_by: {messagenotificationid: desc}) {  agree messagenotificationid  beuserid    userid   type  name   message   creatime  }"
      );
      if (JSON.stringify(info.data.WDMessageNotification) != undefined) {
        this.ConsentApplication.splice(0, this.ConsentApplication.length);
        for (let i = 0; i < info.data.WDMessageNotification.length; i++) {
          this.ConsentApplication.push({
            id: info.data.WDMessageNotification[i].messagenotificationid, //通知ID
            agree: info.data.WDMessageNotification[i].agree, //是否同意
            creatime: showTimeFormat(
              info.data.WDMessageNotification[i].creatime
            ), //时间
            name: info.data.WDMessageNotification[i].name, //团长姓名
            teamName: info.data.WDMessageNotification[i].message //团队名称
          });
        }
      }
    },
    ///打开气泡框
    whole() {
      this.visable = !this.visable;
    },
    //评论和回复
    commt() {
      this.classification = 1;
    },
    ///邀请
    invitation() {
      this.classification = 2;
    },
    ///通知
    notice() {
      this.classification = 3;
    },
    //收藏
    Collection() {
      this.classification = 4;
    },
    //是否采纳该问题
    async adopted(
      wdanswerrefid,
      wdquestionid,
      competePriceSetting,
      beuserid,
      id,
      competePay,
      isViewFree,
      person
    ) {
      if (isViewFree == 2) {
        ///如果是竞价
        this.$confirm(
          "你确定采用这个回答吗？确定将扣除" + competePay + "知识币",
          "系统提示",
          {
            confirmButtonText: "同意",
            cancelButtonText: "拒绝",
            type: "warning",
            showClose: false,
            distinguishCancelAndClose: true,
            closeOnClickModal: false
          }
        )
          ///确认按钮
          .then(() => {
            ///同意采纳
            this.argeeadopted(
              wdanswerrefid,
              wdquestionid,
              competePriceSetting,
              beuserid,
              id,
              competePay,
              isViewFree,
              person
            );
            // updateNews(id); //修改状态为已读
            // /跳转到问题详情里面
            // this.$router.push({
            //   path: "/writeanswer",
            //   query: {
            //     id: wdquestionid,
            //   },
            // });
          })
          // 拒绝按钮
          .catch(() => {
            this.refuseadopted(beuserid, wdanswerrefid);
            // updateNews(id); //修改状态为已读
          });
      } else {
        ////如果不是竞价
        this.$confirm(
          "你确定采用这个回答吗？确定将扣除" + competePriceSetting + "知识币",
          "系统提示",
          {
            confirmButtonText: "同意",
            cancelButtonText: "拒绝",
            type: "warning",
            showClose: false,
            distinguishCancelAndClose: true,
            closeOnClickModal: false
          }
        )
          ///确认按钮
          .then(() => {
            ///同意采纳
            this.argeeadopted(
              wdanswerrefid,
              wdquestionid,
              competePriceSetting,
              beuserid,
              id,
              competePay,
              isViewFree,
              person
            );
            // updateNews(id); //修改状态为已读
            // /跳转到问题详情里面
            // this.$router.push({
            //   path: "/writeanswer",
            //   query: {
            //     id: wdquestionid,
            //   },
            // });
            ///修改团队采纳数
            // this.UserAnswer(beuserid);
          })
          // 拒绝按钮
          .catch(() => {
            this.refuseadopted(beuserid, wdanswerrefid);
            // updateNews(id); //修改状态为已读
          });
      }
    },
    ///计算用户和团队的回答数和采纳数
    async UserAnswer(beuserid) {
      //////////// 统计修改被采纳者的回答数和采纳数
      /////查询被采纳者的采纳数
      let data1 = await QUERYED(
        "post",
        "",
        " WDUserQuestionAnswerRef_aggregate(where: {id: {_eq: " +
          beuserid +
          "}, adopt: {_eq: 1}}) {  aggregate {  count(columns: adopt)   } }"
      );
      //  修改被采纳者的 采纳数
      let update_User = await UPDATE(
        "post",
        "",
        "  update_User(where: {id: {_eq: " +
          beuserid +
          "}}, _set: {wdAcceptNum: " +
          data1.data.WDUserQuestionAnswerRef_aggregate.aggregate.count +
          "}) {  affected_rows  }"
      );
      if (update_User.data.update_User.affected_rows == 1) {
        ///判断该用户是否加入了团队
        let TeamUser = await QUERYED(
          "post",
          "",
          "    WDTeamMember(where: {userid: {_eq: " + beuserid + "}}) { team  }"
        );
        if (TeamUser.data.WDTeamMember.length > 0) {
          //如果加入了团队
          for (let i = 0; i < TeamUser.data.WDTeamMember.length; i++) {
            ///通过ID统计该团队的采纳数
            let dataAc = await QUERYED(
              "post",
              "",
              " WDMemberTeamWdAnswerRef_aggregate(where: {id: {_eq: " +
                TeamUser.data.WDTeamMember[i].team +
                "}}) { aggregate {   count(columns: AcceptNum)  }  }"
            );
            //  修改团队的采纳数
            let update_UP = await UPDATE(
              "post",
              "",
              "  update_WdTeam(where: {id: {_eq: " +
                TeamUser.data.WDTeamMember[i].team +
                "}}, _set: {acceptNum: " +
                dataAc.data.WDMemberTeamWdAnswerRef_aggregate.aggregate.count +
                "}) { affected_rows  }"
            );
            if (update_UP.data.update_WdTeam.affected_rows == 1) {
              //console.log(update_UP);
            }
          }
        }
      }
    },
    //拒绝该答案作为采纳答案
    async refuseadopted(beuserid, wdanswerrefid) {
      //给写回答者发一条拒绝采纳的消息
      let answer = await INSERT(
        "POST",
        "",
        "  insert_MessageNotification(objects: {beuserid: " +
          this.userid +
          ', creatime: "' +
          new Date() +
          '", agree: 2, userid: ' +
          beuserid +
          ", type: 1, state: 3, MessageAnswer: " +
          wdanswerrefid +
          "}) { affected_rows  }"
      );
      if (answer.data.insert_MessageNotification.affected_rows == 1) {
        // 修改回答问题者的状态
        let update = await UPDATE(
          "POST",
          "",
          "  update_WDAnswerRef(where: {id: {_eq: " +
            wdanswerrefid +
            "}}, _set: {adopt: 2}) {affected_rows  }"
        );
        if (update.data.update_WDAnswerRef.affected_rows == 1) {
          this.$message({
            type: "success",
            message: "拒绝成功！"
          });
          this.BeAnsweredwd(); //刷新回答
        }
      }
    },
    //同意采纳该回答作为答案
    async argeeadopted(
      wdanswerrefid,
      wdquestionid,
      competePriceSetting,
      beuserid,
      id,
      competePay,
      isViewFree,
      person
    ) {
      this.loading = true; //显示
      //通过问题ID查询该问题是否有被采纳的回答
      let data1 = await QUERYED(
        "post",
        "",
        "  WDAnswerRef(where: {question: {_eq: " +
          wdquestionid +
          "}, adopt: {_eq: 1}}) {  id  question}"
      );
      //alert(data1.data.WDAnswerRef.length)
      if (data1.data.WDAnswerRef.length > 0) {
        ///如果被采纳了
        this.$alert("该问题已经采纳答案了！", "系统提示：", {
          confirmButtonText: "确定",
          showClose: false,
          distinguishCancelAndClose: true
        });
      } else {
        ///没有被采纳
        ///从我的账户上扣除相应的价钱
        //  查询我的账户余额
        let MYmoney = await QUERYED(
          "POST",
          "",
          " PCUsers(where: {id: {_eq: " + this.userid + "}}) {    Money   }"
        );
        //   判断是不是竞价
        if (isViewFree == 2) {
          //如果是竞价
          competePriceSetting = Number(competePay); //竞价的钱
          //alert(competePriceSetting)
        } else {
          //如果不是竞价
          competePriceSetting == Number(competePriceSetting); ///悬赏的钱
        }
        //    判断我的账户余额够不够
        if (MYmoney.data.PCUsers[0].Money < competePriceSetting) {
          ///月余额不足
          this.$alert("您的余额不足，请先到个人中心充值！", "系统提示：", {
            confirmButtonText: "确定",
            showClose: false,
            distinguishCancelAndClose: true
          });
        } else {
          //////扣除我的
          //如果余额充足
          MYmoney.data.PCUsers[0].Money =
            Number(MYmoney.data.PCUsers[0].Money) - Number(competePriceSetting); //修改我的账户余额
          // 返回我的账户知识币
          let UpdateMoneys = await UPDATE(
            "POST",
            "",
            "  update_User(where: {id: {_eq: " +
              this.userid +
              "}}, _set: {Money: " +
              MYmoney.data.PCUsers[0].Money +
              "}) {   affected_rows  }"
          );
          //查询购买状态
          let ddata = await QUERYED(
            "post",
            "",
            "  WDALLBill(where: {userid: {_eq: " +
              this.userid +
              "}}) {  time  orderNum  id   }"
          );
          //  生成订单编号
          let orderNum = "";
          let num = String(formatZero(this.$store.state.userinfo.id, 7));
          /////生成订单编号
          if (ddata.data.WDALLBill.length < 1) {
            orderNum = getTimeFormat(new Date()) + num + "001";
          } else {
            for (let i = 0; i < ddata.data.WDALLBill.length; i++) {
              if (
                getTimeFormat(new Date()) ==
                getTimeFormat(ddata.data.WDALLBill[i].time)
              ) {
                let sic = ddata.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
                orderNum =
                  getTimeFormat(new Date()) + num + String(formatZero(sic, 3));
                break;
              } else {
                orderNum = getTimeFormat(new Date()) + num + "001";
              }
            }
          }
          ///生成账单 我的支出账单
          let data1 = await INSERT(
            "post",
            "",
            '  insert_bill(objects: {type: 1, state: 0, time: "' +
              new Date() +
              '", userid: ' +
              this.userid +
              ', orderNum: "' +
              orderNum +
              '", orderid: ' +
              wdquestionid +
              ', Price: "' +
              competePriceSetting +
              '", evaluate: "",classification:1}) {affected_rows    returning {    id  }  }'
          );
          if (data1.data.insert_bill.affected_rows == 1) {
            ///我的支出账单添加成功
            if (UpdateMoneys.data.update_User.affected_rows == 1) {
              ///如果修改成功
              ///修改该回答为被采纳状态
              let info = await UPDATE(
                "POST",
                "",
                "  update_WdAnswerRef(where: {id: {_eq: " +
                  wdanswerrefid +
                  "}}, _set: {adopt: 1}) {   affected_rows  }"
              );
              if (info.data.update_WdAnswerRef.affected_rows == 1) {
                ////修改采纳状态成功
                if (person == 0) {
                  //如果是单人回答
                  // 查询写回答者的账户余额  beuserid写回答者ID
                  let Bemoney = await QUERYED(
                    "POST",
                    "",
                    "  PCUsers(where: {id: {_eq: " +
                      beuserid +
                      "}}) {    Money   }"
                  );
                  // 给回答者加上悬赏价钱
                  Bemoney.data.PCUsers[0].Money =
                    Number(Bemoney.data.PCUsers[0].Money) +
                    Number(competePriceSetting);
                  // 修改回答者的账户余额
                  let updateMoney = await UPDATE(
                    "POST",
                    "",
                    "  update_User(where: {id: {_eq: " +
                      beuserid +
                      "}}, _set: {Money: " +
                      Bemoney.data.PCUsers[0].Money +
                      "}) {   affected_rows  }"
                  );
                  if (updateMoney.data.update_User.affected_rows == 1) {
                    ///如果修改成功
                    ////查询采纳者购买状态
                    let ddatas = await QUERYED(
                      "post",
                      "",
                      "  WDALLBill(where: {userid: {_eq: " +
                        beuserid +
                        "} }) {  time  orderNum  id   }"
                    );
                    //  生成订单编号
                    let orderNum1 = "";
                    let num1 = String(formatZero(beuserid, 7));
                    /////生成订单编号
                    if (ddatas.data.WDALLBill.length < 1) {
                      orderNum1 = getTimeFormat(new Date()) + num1 + "001";
                    } else {
                      for (let i = 0; i < ddatas.data.WDALLBill.length; i++) {
                        if (
                          getTimeFormat(new Date()) ==
                          getTimeFormat(ddatas.data.WDALLBill[i].time)
                        ) {
                          let sic =
                            ddatas.data.WDALLBill[0].orderNum.substr(-3) * 1 +
                            1;
                          orderNum1 =
                            getTimeFormat(new Date()) +
                            num1 +
                            String(formatZero(sic, 3));
                          break;
                        } else {
                          orderNum1 = getTimeFormat(new Date()) + num1 + "001";
                        }
                      }
                    }
                    //console.log(
                    //   "时间" + new Date(),
                    //   "用户ID" + beuserid,
                    //   "订单编号" + orderNum1,
                    //   "订单ID" + wdquestionid,
                    //   "价钱" + competePriceSetting
                    // );
                    // 回答者的收入账单
                    let data2 = await INSERT(
                      "post",
                      "",
                      '  insert_bill(objects: {type: 1, state: 0, time: "' +
                        new Date() +
                        '", userid: ' +
                        beuserid +
                        ', orderNum: "' +
                        orderNum1 +
                        '", orderid: ' +
                        wdquestionid +
                        ', Price: "' +
                        competePriceSetting +
                        '", evaluate: "",classification:0}) {affected_rows    returning {    id  }  }'
                    );
                    if (
                      data1.data.insert_bill.affected_rows == 1 &&
                      data2.data.insert_bill.affected_rows == 1
                    ) {
                      ///如果账单生成成功
                      //给写回答者发一条被采纳的消息  competePriceSetting采纳价钱
                      let answer = await INSERT(
                        "POST",
                        "",
                        "  insert_MessageNotification(objects: {beuserid: " +
                          this.userid +
                          ', creatime: "' +
                          new Date() +
                          '", agree: 1, userid: ' +
                          beuserid +
                          ", type: 1, state: 3, MessageAnswer: " +
                          wdanswerrefid +
                          ", bill: " +
                          competePriceSetting +
                          "}) { affected_rows  }"
                      );
                      if (
                        answer.data.insert_MessageNotification.affected_rows ==
                        1
                      ) {
                        ////修改该回答的回答状态
                        let iinfos = await UPDATE(
                          "POST",
                          "",
                          "  update_WdAnswerRef(where: {id: {_eq: " +
                            wdanswerrefid +
                            "}}, _set: {charge: 2}) { affected_rows  }"
                        );
                        if (iinfos.data.update_WdAnswerRef.affected_rows == 1) {
                          this.$message({
                            type: "success",
                            message: "采纳成功！"
                          });
                          integralAddThree(this.userid);
                          // integralAddFive(data1.data.WDAnswerRef[0].question);//发布者
                          // /跳转到问题详情里面
                          this.$router.push({
                            path: "/writeanswer",
                            query: {
                              id: wdquestionid
                            }
                          });
                          // 修改采纳数
                          // AcceptNum(wdanswerrefid); //修改该回答的采纳数
                          ///修改团队采纳数
                          // this.UserAnswer(beuserid);
                          AcceptNum(beuserid); //传递用户ID(修改用户)
                          AcceptNumTeam(beuserid); //传递用户ID（修改团队）
                        } else {
                          this.$message.error("采纳失败！");
                        }
                      } else {
                        this.$message.error("采纳失败！");
                      }
                    }
                  }
                } else if (person == 1) {
                  //console.log(
                  //   "回答ID：",
                  //   wdanswerrefid,
                  //   "悬赏价格：",
                  //   competePriceSetting,
                  //   "问题ID：",
                  //   wdquestionid
                  // );
                  ///如果是多人回答
                  this.DRWD(wdanswerrefid, competePriceSetting, wdquestionid); ///多人问答
                }
              }
            }
          }
        }
      }
      this.loading = false; //显示
    },
    ////多人问答
    async DRWD(wdanswerrefid, competePriceSetting, wdquestionid) {
      ///wdanswerrefid:回答ID，悬赏价格：competePriceSetting,wdquestionid:问题ID
      ////通过回答ID查找外部团队ID\问题ID
      let AnswerRefID = await QUERYED(
        "POST",
        "",
        "WdAnswerRef(where: {id  : {_eq: " +
          wdanswerrefid +
          "}}) {   id  external_problemid external_teamid   }"
      );
      //如果是多人回答
      ////通过外部团队ID查找回答的用户ID
      //console.log(
      //   "外部团队ID：" +
      //     AnswerRefID.data.WdAnswerRef[0].external_teamid +
      //     "外部问题ID：" +
      //     AnswerRefID.data.WdAnswerRef[0].external_problemid
      // );
      let people_answer = await QUERYED(
        "POST",
        "",
        "people_answer(where: {team_id : {_eq: " +
          AnswerRefID.data.WdAnswerRef[0].external_teamid +
          "},ques_id  : {_eq: " +
          AnswerRefID.data.WdAnswerRef[0].external_problemid +
          "}}) {   id  user_id  }"
      );
      //////每个人都应该获得的价钱
      let External_price = (
        Math.round(
          (Number(competePriceSetting) /
            Number(people_answer.data.people_answer.length)) *
            100
        ) / 100
      ).toFixed(2);
      //  Number(answer)/Number(people_answer.data.people_answer.length);
      for (let i = 0; i < people_answer.data.people_answer.length; i++) {
        ///循环扣费
        //console.log(
        //   "多人团队用户ID：" + people_answer.data.people_answer[i].user_id
        // );
        ///通过用户ID查找该用户的账户余额
        let MoenyAdo = await QUERYED(
          "POST",
          "",
          "  PCUsers(where: {id: {_eq: " +
            people_answer.data.people_answer[i].user_id +
            "}}) {   Money  }"
        );
        ////修改该用户的账户余额
        MoenyAdo.data.PCUsers[0].Money =
          Number(MoenyAdo.data.PCUsers[0].Money) + Number(External_price);
        ////修改返回数据库
        let updateMoeny1 = await UPDATE(
          "POST",
          "",
          "  update_User(where: {id: {_eq: " +
            people_answer.data.people_answer[i].user_id +
            "}}, _set: {Money: " +
            MoenyAdo.data.PCUsers[0].Money +
            "}) {  affected_rows  }"
        );
        if (updateMoeny1.data.update_User.affected_rows == 1) {
          //修改成功！
          //////////////////////////生成订单编号//////////////////////////
          ////采纳者的  people_answer.data.people_answer[i].user_id（采纳者id）
          /////查询购买状态
          let ddata1 = await QUERYED(
            "post",
            "",
            "  WDALLBill(where: {userid: {_eq: " +
              people_answer.data.people_answer[i].user_id +
              "}}) {  time  orderNum  id   }"
          );
          //  生成回答问题者的订单编号
          let orderNum1 = "";
          let num1 = String(
            formatZero(people_answer.data.people_answer[i].user_id, 7)
          );
          /////生成订单编号
          if (ddata1.data.WDALLBill.length < 1) {
            orderNum1 = getTimeFormat(new Date()) + num1 + "001";
          } else {
            for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
              if (
                getTimeFormat(new Date()) ==
                getTimeFormat(ddata1.data.WDALLBill[i].time)
              ) {
                let sic = ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
                orderNum1 =
                  getTimeFormat(new Date()) + num1 + String(formatZero(sic, 3));
              } else {
                orderNum1 = getTimeFormat(new Date()) + num1 + "001";
              }
            }
          }
          //console.log(
          //   "时间" + new Date(),
          //   "用户ID" + people_answer.data.people_answer[i].user_id,
          //   "订单编号" + orderNum1,
          //   "订单ID" + wdquestionid,
          //   "价钱" + External_price
          // );
          ////给采纳者账单加入一条收入数据
          let data2 = await INSERT(
            "post",
            "",
            '  insert_bill(objects: {type: 1, state: 0, time: "' +
              new Date() +
              '", userid: ' +
              people_answer.data.people_answer[i].user_id +
              ', orderNum: "' +
              orderNum1 +
              '", orderid: ' +
              wdquestionid +
              ', Price: "' +
              External_price +
              '", evaluate: "",classification:0}) {affected_rows    returning {    id  }  }'
          );
          //console.log(
          //   "采纳者账单存入成功！" + data2.data.insert_bill.returning[0].id
          // );
          ///通过问题ID查询问题内容
          let questions = await QUERYED(
            "POST",
            "",
            "  WDQuestion(where: {id: {_eq: " +
              wdquestionid +
              "}}) { questionTitle  }"
          );
          // //console.log('收到消息的ID'+question.data.WDQuestion[0].questioner,"时间"+new Date()+"发消息的ID"+user,"问题"+questions.data.WDQuestion[0].questionTitle,"发布者价钱"+release,"采纳者价钱"+answer)
          ////给采纳者发一条消息通知他查看
          let seeData = await INSERT(
            "post",
            "",
            "  insert_MessageNotification(objects: {type: 1, userid: " +
              people_answer.data.people_answer[i].user_id +
              ', creatime: "' +
              new Date() +
              '", beuserid:' +
              this.$store.state.userinfo.id +
              ',message:"' +
              questions.data.WDQuestion[0].questionTitle +
              '",bill : ' +
              External_price +
              ",state :2}) { affected_rows  }"
          );
          if (seeData.data.insert_MessageNotification.affected_rows == 1) {
            //console.log("消息发送成功！");
            AcceptNum(people_answer.data.people_answer[i].user_id); //传递用户ID(修改用户)(每个用户修改一次)
            if (i == 0) {
              // 、、、一个团队修改只修改一次采纳数
              AcceptNumTeam(people_answer.data.people_answer[i].user_id); //传递用户ID（修改团队）
            }
          }
        }
      }
      this.$message({
        type: "success",
        message: "采纳成功！"
      });
      integralAddThree(this.userid);
      //  integralAddFive(data1.data.WDAnswerRef[0].question);//发布者
      // /跳转到问题详情里面
      this.$router.push({
        path: "/writeanswer",
        query: {
          id: wdquestionid
        }
      });
    },
    // 是否同意去回答
    async answer(wdquestionid, userid, questionTitle, id) {
      this.$confirm("是否同意去回答该问题？", "系统提示", {
        confirmButtonText: "同意",
        cancelButtonText: "取消",
        type: "warning",
        showClose: false,
        distinguishCancelAndClose: true,
        closeOnClickModal: false
      })
        ///确认按钮
        .then(() => {
          // updateNews(id); //修改状态为已读
          ///跳转到问题详情里面
          this.$router.push({
            path: "/writeanswer",
            query: {
              id: wdquestionid
            }
          });
        })
        // 拒绝按钮
        .catch(() => {
          // updateNews(id); //修改状态为已读
          // this.JJquestion(wdquestionid, userid, questionTitle); ////已拒绝某人的回答问题邀请
        });
    },
    ///拒绝某人邀请我回答的问题
    async JJquestion(wdquestionid, userid, questionTitle) {
      ////删除邀请表中的邀请记录
      let data1 = await DELETE(
        "POST",
        "",
        "   delete_WdInvitation(where: {user: {_eq: " +
          userid +
          "}, userid: {_eq: " +
          this.userid +
          "}, questioner: {_eq: " +
          wdquestionid +
          "}}) {   affected_rows }"
      );
      if (data1.data.delete_WdInvitation.affected_rows == 1) {
        ///给该用户发一条消息通知（拒绝）
        let info = await INSERT(
          "POST",
          "",
          "   insert_MessageNotification(objects: {agree: 2, userid: " +
            userid +
            ', creatime: "' +
            new Date() +
            '", message: "' +
            questionTitle +
            '", beuserid: ' +
            this.userid +
            ", type: 2}) { affected_rows }"
        );
        if (info.data.insert_MessageNotification.affected_rows == 1) {
          this.$message({
            type: "success",
            message: "已拒绝！"
          });
        }
      }
    },
    // 是否同意去团队回答
    async Teamanswer(wdqueid, userid, teamid, questionTitle) {
      this.$confirm("是否同意去回答该团队问题？", "系统提示", {
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
        type: "warning",
        showClose: false,
        closeOnClickModal: false
      })
        ///确认按钮
        .then(() => {
          this.agreddTeamanswer(wdqueid, userid, teamid); //同意回答
        })
        // 拒绝按钮
        .catch(() => {
          // updateNews(id); //修改状态为已读
          this.JJquestion(wdqueid, userid, questionTitle); ////已拒绝某人的回答问题邀请
        });
    },
    ///同意去参加回答
    async agreddTeamanswer(wdqueid, userid, teamid) {
      // 通过团队ID、问题ID、用户ID查找外部的ID
      let info = await QUERYED(
        "POST",
        "",
        "WdAnswerRef(where: {team : {_eq: " +
          teamid +
          "},question : {_eq: " +
          wdqueid +
          "},person:{_eq: 1}, user : {_eq: " +
          userid +
          "}}) { external_problemid  external_teamid   }"
      );
      if (info.success && info.data.WdAnswerRef.length > 0) {
        window.location.href =
          "http://www.izhihui.net/api/user/add/token?token=" +
          localStorage.getItem("Authorization") +
          "&targetUrl=" +
          "http://www.izhihui.net/zz/#/home/ques/details?quesId=" +
          info.data.WdAnswerRef[0].external_problemid +
          "&teamId=" +
          info.data.WdAnswerRef[0].external_teamid +
          "&userId=" +
          this.$store.state.userinfo.id +
          "&ttlMillis=" +
          7200 * 1000 +
          "&teamlbid=" +
          teamid;
      }
    },

    // 是否同意加入团队
    async agree(Wdnewsid, id, wdteamid, user, teamName) {
      this.$confirm("是否同意他（她）加入该团队？", "系统提示", {
        confirmButtonText: "同意",
        cancelButtonText: "拒绝",
        type: "warning",
        showClose: false,
        distinguishCancelAndClose: true,
        closeOnClickModal: false
      })
        ///确认按钮
        .then(() => {
          this.agTeam(Wdnewsid, id, wdteamid, user, teamName); /////同意入团
          ///刷新是否同意申请入团
          this.applyTeamS();
          // updateNews(id); ///修改为已读
        })
        // 拒绝按钮
        .catch(() => {
          this.JJTeam(Wdnewsid, id, wdteamid, user, teamName); ///拒绝入团
          ///刷新是否同意申请入团
          this.applyTeamS();
          // updateNews(id); ///修改为已读
        });
      //   this.$message({
      //         type:"warning",
      //         message:"无操作！"
      //     })
    },
    ///同意入团
    async agTeam(Wdnewsid, id, wdteamid, user, teamName) {
      // 判断该用户是否已加入团队
      let info2 = await QUERYED(
        "POST",
        "",
        "  WDTeamMember(where: {team: {_eq: " +
          wdteamid +
          "}, memberId: {_eq: " +
          user +
          "}}) {id  }"
      );
      if (info2.data.WDTeamMember.length > 0) {
        ///如果已经在该团队
        this.$alert("该用户已经在团队了！", "系统提示：", {
          confirmButtonText: "确定",
          showClose: false,
          distinguishCancelAndClose: true
        });
      } else {
        //同意该用户加入我的团队
        let data = await INSERT(
          "POST",
          "",
          '  insert_WdTeamMember(objects: {Leader: "", joinTime: "' +
            new Date() +
            '", member: ' +
            user +
            ", team: " +
            wdteamid +
            "}) { affected_rows   returning { id  }  }"
        );
        if (data.data.insert_WdTeamMember.affected_rows == 1) {
          ////删除申请表中的该用户的申请记录
          let data1 = await DELETE(
            "POST",
            "",
            "  delete_Wdnews(where: {user: {_eq: " +
              user +
              "}, wdteam: {_eq: " +
              wdteamid +
              "}}) {   affected_rows  }"
          );
          if (data1.data.delete_Wdnews.affected_rows == 1) {
            ///给该用户发一条消息通知（同意）
            let info = await INSERT(
              "POST",
              "",
              "   insert_MessageNotification(objects: {agree: 1, userid: " +
                user +
                ', creatime: "' +
                new Date() +
                '", message: "' +
                teamName +
                '", beuserid: ' +
                this.userid +
                ", type: 1,state :0}) { affected_rows }"
            );
            if (info.data.insert_MessageNotification.affected_rows == 1) {
              this.$message({
                type: "success",
                message: "同意成功！"
              });
            }
            AcceptNum(user); //传递用户ID(修改用户)
            AcceptNumTeam(user); //传递用户ID（修改团队）
            //    if(info.data.WDMessageNotification)
          }
        }
      }
    },
    //    拒绝入团申请
    async JJTeam(Wdnewsid, id, wdteamid, user, teamName) {
      ////删除申请表中的该用户的申请记录
      let data1 = await DELETE(
        "POST",
        "",
        "  delete_Wdnews(where: {user: {_eq: " +
          user +
          "}, wdteam: {_eq: " +
          wdteamid +
          "}}) {   affected_rows  }"
      );
      if (data1.data.delete_Wdnews.affected_rows == 1) {
        ///给该用户发一条消息通知（同意）
        let info = await INSERT(
          "POST",
          "",
          "   insert_MessageNotification(objects: {agree: 2, userid: " +
            user +
            ', creatime: "' +
            new Date() +
            '", message: "' +
            teamName +
            '", beuserid: ' +
            this.userid +
            ", type: 1,state :0}) { affected_rows }"
        );
        if (info.data.insert_MessageNotification.affected_rows == 1) {
          this.$message({
            type: "success",
            message: "已拒绝！"
          });
          this.init();
        }
        //    if(info.data.WDMessageNotification)
      }
    },
    async seede(wdquestionid, id) {
      // updateNews(id); ///修改为已读
      ///跳转到问题详情里面
      this.$router.push({
        path: "/writeanswer",
        query: {
          id: wdquestionid
        }
      });
    },
    //    删除消息
    async deletes(id) {
      this.loading = true; //显示
      let data = await DELETE(
        "POST",
        "",
        " delete_MessageNotification(where: {id: {_eq: " +
          id +
          "}}) {  affected_rows  }"
      ); ///删除消息通知
      if (data.data.delete_MessageNotification.affected_rows == 1) {
        this.$message({
          type: "success",
          message: "删除成功！"
        });
        // 刷新页面
        this.init();
        this.loading = false; //显示
      }
    },
    dataFormat(time) {
      return `${time.getFullYear()}-${
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1)
      }-${time.getDate() >= 10 ? time.getDate() : "0" + time.getDate()} ${
        time.getHours() >= 10 ? time.getHours() : "0" + time.getHours()
      }:${
        time.getMinutes() >= 10 ? time.getMinutes() : "0" + time.getMinutes()
      }:${
        time.getSeconds() >= 10 ? time.getSeconds() : "0" + time.getSeconds()
      }`;
    }
  }
};
</script>
<style scoped>
.Notification {
  /* display: inline-block;
  width: 100%;
  background-color: #f7f7f7; */
  /* border: 1px solid red; */
}
.content {
  /* width: 70%;
  margin: auto; */
  /* border: 1px solid darkgreen; */
  /* background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px; */
}
.content_top {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid rgba(125, 125, 125, 1);
}
.size {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
}
.size-right {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(103, 103, 103, 1);
  float: right;
  cursor: pointer;
  display: inline-block;
}
.content_center {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  /* line-height: 50px; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgb(83, 83, 83);
}
.img {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-image: url("~@/assets/bigLOGO.png");
  position: relative;
  top: 3px;
  left: -5px;
}
.demo1 {
  width: 100px;
  background: white;
  /* border:1px solid rgba(124,124,124,1); */
  border-radius: 5px;
  display: inline-block;
  position: relative;
  top: -10px;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(87, 87, 87, 0.44);
}
.demo1 > span {
  width: 100%;
  /* border: 1px solid red; */
  line-height: 30px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

/*气泡框效果实现*/

.demo {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: white;
  /* border:1px solid rgba(124,124,124,1); */
  transform: rotate(50deg);
  position: relative;
  left: -40px;
  top: 5px;
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(87, 87, 87, 0.44);
}
.right_rigth {
  width: 100%;
  text-align: right;
  display: inline-block;
  /* border: 1px solid red; */
}
.wid {
  width: 100%;
  text-align: right;
  display: inline-block;
}
.imges {
  background-image: url("~@/assets/icon/down1.png");
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  position: relative;
  top: 10px;
}
.demo1 > span:hover {
  color: #0084ff;
}
.classification {
  color: #0084ff;
  /* font-weight: bold; */
  /* cursor:pointer */
}
.blue {
  color: #0084ff;
  cursor: pointer;
  text-decoration: underline;
}
/* 组件 */
.contentds {
  width: 100%;
  /* border: 1px solid rebeccapurple; */
}
.assembly {
  width: 100%;
  /* border: 1px solid darkgoldenrod; */
}
.assembly_top {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
}
.TTcommt > div,
.TTCollection > div,
.ZNCollection > div,
.ZNcommt > div,
.ZNinvitation > div,
.ZNnotice > div,
.ZYcommt > div,
.ZYCollection > div {
  width: 100%;
  /* height: 30px; */
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* border: 1px solid red; */
}
/* .TTcommt>div :hover,.TTCollection>div :hover,.ZNCollection>div :hover,.ZNcommt>div :hover,.ZNinvitation>div :hover,.ZNnotice>div :hover,.ZYcommt>div :hover,.ZYCollection>div :hover{
    background-color: bisque;
    display: inline-block;
} */
.tttop:hover {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 5px;
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(219, 219, 219, 0.44);
}
.name {
  font-weight: bold;
  margin-right: 10px;
  /* margin-left: 10px; */
}
.just {
  /* margin-left: 10px; */
  color: #006cff;
  margin-right: 100px;
}
.td {
  margin-right: 10px;
}
.tu {
  width: 3px;
  height: 3px;
  background: rgba(0, 132, 255, 1);
  border-radius: 80%;
  display: inline-block;
  margin-right: 3px;
}
.cha {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url("~@/assets/icon/fork.png");
  position: relative;
  top: 8px;
  left: 5px;
  cursor: pointer;
}
.tops {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* border: 1px solid red; */
  height: 40px;
  /* line-height: 30px; */
  /* margin-bottom: 20px; */

  /* display: inline-block; */
}
.people {
  width: 95%;
  border: 1px solid #d6d6d6;
  padding: 1%;
  margin: auto;
  border-radius: 5px;
}
.feed {
  /* word-wrap: break-word; */
  /* display: inline-block; */
  word-wrap: break-word;
  word-break: break-all;
  /* 超出范围后换行 */
  white-space: pre-wrap;
  /* border: 1px dashed; */
}
::v-deep .el-timeline-item__tail {
  border-left: 1px dashed #a6beec;
}
::v-deep .el-timeline-item {
  padding-bottom: 1px;
}
.postion {
  position: relative;
  top: -8px;
  margin-left: 5px;
}
</style>